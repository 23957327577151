"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateGoogleLinks = void 0;
const sprintf_js_1 = require("sprintf-js");
const googleSearch = "https://www.google.com/search?q=%s";
function generateGoogleLinks() {
    document.querySelectorAll("a.gLink").forEach(function (element) {
        const link = element;
        const query = link.dataset.query;
        if (!query) {
            return;
        }
        link.href = (0, sprintf_js_1.sprintf)(googleSearch, encodeURIComponent(query));
    });
}
exports.generateGoogleLinks = generateGoogleLinks;
