"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coverThumbnail = void 0;
const js_base64_1 = require("js-base64");
const sprintf_js_1 = require("sprintf-js");
const imageServer = "https://img.morow.com/sign/fit/%s/%s/ce/0/%s.jpg";
function coverThumbnail(coverUrl, size) {
    size = size * window.devicePixelRatio;
    let thumbnailUrl;
    if (coverUrl) {
        const base64Url = js_base64_1.Base64.encodeURL(coverUrl);
        thumbnailUrl = (0, sprintf_js_1.sprintf)(imageServer, size, size, base64Url);
    }
    else {
        thumbnailUrl = "/build/images/cover.jpg";
    }
    return thumbnailUrl;
}
exports.coverThumbnail = coverThumbnail;
